// import appStrings from '@/app/utility/string.utility';

export default {
  name: 'awardDetails',
  props: ['headerAllData', 'rowData', 'rfpHeaderId'],
  data() {
    return {
      legalEntity: null,
      userDepartment: null,
      startDate: null,
      endDate: null,
      bidType: null,
      paymentTerms: null,
      budget: null,
      retention: null,
      boqType: null,
      requestorName: null,
      requestorEmail: null,
      billingToSite: null,
      shipToSite: null,
      contactPersonName: null,
      contactPersonMobile: null,
      grnCreator: null,
      ticketNumber: null,
      gstTaxRate: null,
      gstTaxType: null,
      clientName: null,
      clientApprovalMail: null,
      operatingUnit: null,
      area: null,
      contract: null,
      projectCarpetArea: null,
      officeMezzanineArea: null,
      sorterMezzanineArea: null,
      vendorName: null,
      vendorEmail: null,
      vendorCode: null,
      level: null,
      totalAmount: null,
      rfpNumber: null,
      taxedData: [],
      taxedFields: [
        {
          key: 'boq_line_code',
          label: 'Oracle Line Description'
        },
        {
          key: 'tax_type'
        },
        {
          key: 'tax_rate'
        }
      ],
      showValueSetModal: false,
      vsetCode: null,
      parent_value_set_id: null,
      selectedIndex: null
    };
  },
  mounted() {
    this.getParentToChildData(this.headerAllData, this.rowData);
    this.getBoqLineCode();
  },
  methods: {
    getParentToChildData(data, rowdata) {
      this.legalEntity = data.le_name;
      this.userDepartment = data.user_department;
      this.rfpNumber = data.rfp_number;
      this.bidType =
        data.rfp_type === 'NRC_CE'
          ? 'Non-Rate Contract Closed Envelope'
          : 'Rate Contract Closed Envelope';
      this.paymentTerms = data.payment_terms;
      this.budget = data.budget;
      this.retention = data.retention;
      this.startDate = this.convertDateFormat(data.start_date);
      this.endDate = this.convertDateFormat(data.endDate);
      this.boqType = data.boqType;
      this.vendorName = rowdata.rfp_award_vendor_name;
      this.vendorEmail = rowdata.rfp_award_vendor_email;
      this.vendorCode = rowdata.vendor_code;
      this.level = rowdata.rank;
      this.totalAmount = rowdata.total;
      this.requestorName = data.creator_name;
      this.requestorEmail = data.creator_email;
    },
    convertDateFormat(inputDate) {
      const [datePart, timePart] = inputDate.split(' ');
      const [day, month, year] = datePart.split('-');
      const convertedDate = `${year}-${month}-${day}T${timePart}`;
      return convertedDate;
    },
    getBoqLineCode() {
      let payload = {
        rfp_header_id: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getBoqLineCode', payload)
        .then(response => {
          if (response.status === 200) {
            this.loading = false;
            let result = response.data.data.page;
            this.taxedData = result;
          } else {
            alert('Something went wrong');
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openValueSetModal(vsetCode, index) {
      this.selectedIndex = index;
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'RFP_TAX_CATEGORY') {
        this.taxedData[this.selectedIndex].tax_type = item.tax_type;
      } if (this.vsetCode === 'TAX_VALUES') {
        this.taxedData[this.selectedIndex].tax_rate = item.value_code;
      }
      setTimeout(() => {
         this.taxedData = JSON.parse(JSON.stringify(this.taxedData))
      }, 0)
      this.showValueSetModal = false;
    },
    copyTaxToAll() {
      let taxType = this.taxedData[0].tax_type;
      let taxRate = this.taxedData[0].tax_rate;
      for(let i of this.taxedData) {
        i.tax_type = taxType;
        i.tax_rate = taxRate;
      }
    }
  }
}; 

