import commonHelper from '@/app/utility/common.helper.utility';
import XLSX from 'xlsx';
import URL_UTILITY from '../../../../../../utility/url.utility';

export default {
  name: 'generateComparative',
  props: [
    'roundData',
    'roundProps',
    'itemBasis',
    'rfpHeaderId',
    'negotiationSequence'
  ],
  data() {
    return {
      generateComparativeData: [],
      generateComparativeFields: [
        {
          key: 'location_name',
          label: 'Location',
          class: 'text-center'
        },
        {
          key: 'cost_centre',
          class: 'text-center'
        },
        {
          key: 'item_code',
          label: 'Item Code',
          class: 'text-center'
        },
        {
          key: 'item_description',
          class: 'text-center'
        },
        {
          key: 'quantity',
          label: 'Quantity',
          class: 'text-center'
        },
        {
          key: 'name',
          label: 'Vendor Name',
          class: 'text-center'
        },
        {
          key: 'negotiation_seq',
          label: 'Round',
          class: 'text-center'
        },
        {
          key: 'version',
          label: 'Version',
          class: 'text-center'
        },
        {
          key: 'unit_price',
          label: 'Price',
          class: 'text-center'
        },
        {
          key: 'attributes',
          label: 'Attributes',
          class: 'text-center'
        }
      ],
      locationName: {
        value: null,
        text: null
      },
      options: [],
      currentPage: 1,
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      rfpBasis: null,
      popoverContent: null,
      requestId: null,
      templateId: null,
      requestStatus: null,
      reqId: null,
      dowloadReport: URL_UTILITY.getDownloadReportUrl,
      boqFlag: false,
      generateComparativeDataForBoq: [],
      generateComparativeFieldsForBoq: [],
      boqComparativeApiData: []
    };
  },
  watch: {
    currentPage: function() {
      this.getGenerateComparative();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGenerateComparative();
    }
  },
  mounted() {
    let locations = this.$store.state.rfp.rfpState;
    this.options = locations;
    this.rfpBasis = this.itemBasis;
  },
  methods: {
    getData(inputData) {
      const names = inputData[0].comp_detail.split('|');
      const dataArray = inputData
        .slice(1)
        .map(obj => obj.comp_detail.split('|'));
      const result = dataArray.map(data => {
        return data.reduce((acc, value, index) => {
          acc[names[index]] = value;
          return acc;
        }, {});
      });
      this.generateComparativeData = result;
      let arr = [];
      for (let i of result) {
        for (let key in i) {
          if (key.includes('F_V') || key.includes('I_V')) {
            i[key] = +i[key];
          }
        }
      }
      for (let i of result) {
        let array = [];
        for (let key in i) {
          if (key.includes('F_V')) {
            array.push(i[key]);
          }
        }
        let smallest = Infinity;
        for (let j = 0; j < array.length; j++) {
          if (+array[j] < +smallest) {
            smallest = +array[j];
          }
        }
        arr.push(smallest);
      }
      for (let i = 0; i < this.generateComparativeData.length; i++) {
        for (let key in this.generateComparativeData[i]) {
          if (key.includes('F_V')) {
            let cellValue = +this.generateComparativeData[i][key];
            if (cellValue === arr[i]) {
              let cellVariantKey = key;
              if (!this.generateComparativeData[i]['_cellVariants']) {
                this.$set(this.generateComparativeData[i], '_cellVariants', {});
              }
              this.$set(
                this.generateComparativeData[i]._cellVariants,
                cellVariantKey,
                'success'
              );
            }
          }
        }
      }
      setTimeout(() => {
        this.generateComparativeData = JSON.parse(
          JSON.stringify(this.generateComparativeData)
        );
      }, 0);
    },
    downloadExcelData(type) {
      this.makeToast('Excel Sheet downloading!', 'success');
      if (type === 'item') {
        const ws = XLSX.utils.json_to_sheet(this.generateComparativeData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Comparative');
        XLSX.writeFile(wb, 'comparative.xlsx');
      } else {
        const columnHeaders = this.generateComparativeFieldsForBoq.map(
          field => field.label
        );
        let excelData = this.generateComparativeDataForBoq.map(row => {
          let formattedRow = {};
          this.generateComparativeFieldsForBoq.forEach(field => {
            formattedRow[field.label] = row[field.key] || '-';
          });
          return formattedRow;
        });
        const ws = XLSX.utils.json_to_sheet(excelData, {
          header: columnHeaders
        });
        ws['!cols'] = columnHeaders.map(() => ({ wch: 20 }));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'BOQ Comparison');
        XLSX.writeFile(wb, 'BOQ_Comparison.xlsx');
      }
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast',
        autoHideDelay: 2000
      });
    },
    getCellClass(value) {
      if (value === 'Red') return 'bg-danger';
      if (value === 'Green') return 'bg-success';
      if (value === 'Blue') return 'bg-primary';
      return '';
    },
    getGenerateComparative() {
      if (this.itemBasis === 'item') {
        this.boqFlag = false;
        let payload = {
          rfp_header_id: this.rfpHeaderId,
          negotiation_seq: this.negotiationSequence
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/getRfpNewComparative', payload)
          .then(response => {
            if (response.status === 200) {
              let result = response.data.data;
              this.generateComparativeData = result;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else if (this.itemBasis === 'BOQ') {
        this.boqFlag = true;
      }
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: this.$store.state.shared.menuId
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    },
    downloadPdf() {
      const payload = {
        template_name: 'RFP_ITEM_COMPARISON'
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getRfpReqId', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.requestId = resp.data.data.request_id;
            this.templateId = resp.data.data.template_id;
            this.saveSubmitRequest();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveSubmitRequest() {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap', //pass hardcode
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'PDF', //pass hardcode
            no_of_args: 25,
            request_parameter:
              this.rfpHeaderId + ',' + this.negotiationSequence,
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: this.rfpHeaderId,
            argument2: this.negotiationSequence,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            this.getSubmitRequest();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubmitRequest() {
      this.loader = true;
      this.$store
        .dispatch('template/getSubmitRequest', this.requestNumId)
        .then(resp => {
          // this.loader = false;
          if (resp.status === 200) {
            this.requestStatus = resp.data.data[0].status;
            this.reqId = resp.data.data[0].id;
            if (this.requestStatus === 'ERROR') {
              this.loader = false;
              this.requestStatus = null;
              alert('Request in ERROR can not Preview');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadReport() {
      window.location.href = this.dowloadReport + '/' + this.reqId;
      this.requestStatus = null;
    },
    processDataForDetail() {
      this.generateComparativeFieldsForBoq = [
        {
          key: 'sheet_name',
          label: 'Sheet Name',
          class: 'narrow-col'
        },
        {
          key: 'r_number',
          label: 'Row No',
          class: 'narrow-col'
        },
        {
          key: 's_number',
          label: 'Serial No',
          class: 'narrow-col'
        },
        {
          key: 'negotiation_seq',
          label: 'Round',
          class: 'narrow-col'
        },
        {
          key: 'location_name',
          label: 'Location',
          class: 'narrow-col'
        },
        {
          key: 'boq_line_code',
          label: 'Activity Code',
          class: ''
        },
        {
          key: 'boq_line_subcode',
          label: 'Subcode',
          class: ''
        },
        {
          key: 'boq_description',
          label: 'Description',
          class: 'col-fix-wrap'
        },
        {
          key: 'uom',
          label: 'UOM',
          class: 'narrow-col'
        },
        {
          key: 'boq_quantity',
          label: 'Quantity',
          class: 'text-right col-wrap'
        }
      ];
      let vendors = new Set();
      this.boqComparativeApiData.forEach(item => {
        item.vendor_unit_price_details.forEach(vendor => {
          vendors.add(vendor.vendor_name);
        });
      });
      vendors.forEach(vendor => {
        this.generateComparativeFieldsForBoq.push(
          {
            key: `${vendor}_unit_price`,
            label: `${vendor} Unit Price`,
            class: 'text-right col-wrap'
          },
          {
            key: `${vendor}_total_amount`,
            label: `${vendor} Total Amount`,
            class: 'text-right col-wrap'
          }
        );
      });
      let totalsRow = {
        sheet_name: 'Total',
        _rowVariant: 'info',
        rowClass: 'font-weight-bold'
      };
      this.generateComparativeDataForBoq = this.boqComparativeApiData.map(
        item => {
          let row = { ...item };
          vendors.forEach(vendor => {
            let vendorData = item.vendor_unit_price_details.find(
              v => v.vendor_name === vendor
            );
            row[`${vendor}_unit_price`] = vendorData
              ? `₹ ${Number(vendorData.unit_price).toFixed(2)}`
              : '-';

            row[`${vendor}_total_amount`] = vendorData
              ? `₹ ${Number(vendorData.total_amount).toFixed(2)}`
              : '-';
            totalsRow[`${vendor}_total_amount`] =
              (totalsRow[`${vendor}_total_amount`] || 0) +
              (vendorData ? Number(vendorData.total_amount) : 0);
          });

          return row;
        }
      );
      vendors.forEach(vendor => {
        if (totalsRow[`${vendor}_total_amount`] !== undefined) {
          totalsRow[`${vendor}_total_amount`] = `₹ ${totalsRow[
            `${vendor}_total_amount`
          ].toFixed(2)}`;
        }
      });
      this.generateComparativeDataForBoq.push(totalsRow);
    },
    truncateString(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    getBoqComparative(type) {
      if (type === 'detail') {
        this.getBoqDetailComparative();
      } else if (type === 'grand') {
        this.getBoqGrandSummaryComparative();
      } else if (type === 'activityCode') {
        this.getBoqActivityCodeSummaryComparative();
      }
    },
    getBoqDetailComparative() {
      let payload = {
        rfp_header_id: this.rfpHeaderId,
        negotiation_seq: this.negotiationSequence
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getBoqGenerateComparative', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.boqComparativeApiData = result;
            for (let i of this.boqComparativeApiData) {
              i.boq_quantity = Number(i.boq_quantity).toFixed(2);
            }
            this.processDataForDetail();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getBoqGrandSummaryComparative() {
      let payload = {
        rfp_header_id: this.rfpHeaderId,
        negotiation_seq: this.negotiationSequence
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getGrandSummaryComparative', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.boqComparativeApiData = result;
            this.processDataForSheetSummary();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    processDataForSheetSummary() {
      this.generateComparativeDataForBoq = [];
      this.generateComparativeFieldsForBoq = [
        { key: 'sheet_name', label: 'Sheet Name', class: 'text-center' }
      ];
      let vendorNames = new Set();
      let totalsRow = { sheet_name: 'Total', _rowVariant: 'info' };
      this.boqComparativeApiData.forEach(sheet => {
        sheet.vendor_details_summ_comp.forEach(vendor => {
          vendorNames.add(vendor.vendor_name);
        });
      });
      let vendorArray = Array.from(vendorNames);
      vendorArray.forEach(vendor => {
        this.generateComparativeFieldsForBoq.push({
          key: vendor,
          label: `${vendor} Total Amount`,
          class: 'text-right nowrap-column'
        });
        totalsRow[vendor] = 0;
      });
      this.boqComparativeApiData.forEach(sheet => {
        let row = { sheet_name: sheet.sheet_name };
        vendorArray.forEach(vendor => {
          let vendorData = sheet.vendor_details_summ_comp.find(
            v => v.vendor_name === vendor
          );
          let totalAmount =
            vendorData && vendorData.total_amount !== null
              ? Number(vendorData.total_amount)
              : 0;
          row[vendor] =
            vendorData && vendorData.total_amount !== null
              ? `₹${vendorData.total_amount.toFixed(2)}`
              : '0';
          totalsRow[vendor] += totalAmount;
        });

        this.generateComparativeDataForBoq.push(row);
      });
      vendorArray.forEach(vendor => {
        totalsRow[vendor] = `₹${totalsRow[vendor].toFixed(2)}`;
      });
      this.generateComparativeDataForBoq.push(totalsRow);
    },
    getBoqActivityCodeSummaryComparative() {
      let payload = {
        rfp_header_id: this.rfpHeaderId,
        negotiation_seq: this.negotiationSequence
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getActivityCodeSummaryComparative', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.boqComparativeApiData = result;
            this.processDataForActivityCodeSummary();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    processDataForActivityCodeSummary() {
      this.generateComparativeDataForBoq = [];
      this.generateComparativeFieldsForBoq = [
        { key: 'sheet_name', label: 'Sheet Name', class: 'text-center' },
        { key: 'boq_line_code', label: 'Activity Code', class: 'text-center' }
      ];

      let vendorNames = new Set();
      let totalsRow = {
        sheet_name: 'Total',
        boq_line_code: '-',
        _rowVariant: 'info'
      };
      this.boqComparativeApiData.forEach(sheet => {
        sheet.vendor_details_summ_comp.forEach(vendor => {
          vendorNames.add(vendor.vendor_name);
        });
      });
      let vendorArray = Array.from(vendorNames);
      vendorArray.forEach(vendor => {
        this.generateComparativeFieldsForBoq.push({
          key: vendor,
          label: `${vendor} Total Amount`,
          class: 'text-right nowrap-column'
        });
        totalsRow[vendor] = 0;
      });
      this.boqComparativeApiData.forEach(sheet => {
        let row = {
          sheet_name: sheet.sheet_name,
          boq_line_code: sheet.boq_line_code
        };
        vendorArray.forEach(vendor => {
          let vendorData = sheet.vendor_details_summ_comp.find(
            v => v.vendor_name === vendor
          );
          let totalAmount = vendorData ? Number(vendorData.total_amount) : 0;

          row[vendor] = `₹${totalAmount.toFixed(2)}`;
          totalsRow[vendor] += totalAmount;
        });
        this.generateComparativeDataForBoq.push(row);
      });
      vendorArray.forEach(vendor => {
        totalsRow[vendor] = `₹${totalsRow[vendor].toFixed(2)}`;
      });
      this.generateComparativeDataForBoq.push(totalsRow);
    }
  },
  beforeDestroy() {
    this.eventBus.$off('state');
  }
};
