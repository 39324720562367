import initiateNegotiation from './initiateNegotiation';
import round from './round';
import generateComparative from './generateComparative';
import XLSX from 'xlsx';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';

export default {
  name: 'negotiationRounds',
  props: ['negotiationAllData', 'rfphdrid', 'headerAllData', 'menuFromDashboard'],
  components: {
    initiateNegotiation,
    round,
    generateComparative,
    UploadDocument,
    HistoryRecord,
    ApprovalHistory,
    sendForApproval
  },
  data() {
    return {
      previousRound: -1,
      currentRound: 0,
      nextRound: 1,
      roundData: [],
      roundFields: [
        {
          key: 'negotiation_seq',
          label: 'Round',
          class: 'col-fix text-center'
        },
        {
          key: 'start_date',
          label: 'Initiation Date',
          class: 'col-fix text-center'
        },
        {
          key: 'end_date',
          class: 'col-fix text-center'
        },
        {
          key: 'status',
          class: 'col-fix text-center'
        },
        {
          key: 'remarks',
          class: 'col-fix text-center'
        },

        {
          key: 'close_negotiation',
          class: 'col-fix text-center'
        }
      ],
      showOpenRoundsModal: false,
      showOpenInitiateModal: false,
      rfpHeaderId: null,
      vendorData: [],
      negotiationHdrId: null,
      roundProps: {},
      showOpenCloseNegotiationModal: false,
      selectedOption: '',
      roundIndex: null,
      remarks: null,
      autofocus: true,
      rfpLocationFlag: false,
      rfpItemFlag: false,
      rfpBoqFlag: false,
      rfpInitiateBidFlag: false,
      showOpenGenerateComparativeModal: false,
      roundStatus: null,
      rfpClosedFlag: false,
      itemBasis: null,
      negotiationSequence: null,
      showOpenNewGenerateComparativeModal: false,
      generateComparativeData: [],
      generateComparativeFields: [
        {
          key: 'location_name',
          label: 'Location',
          class: 'text-center'
        },
        {
          key: 'cost_centre',
          class: 'text-center'
        },

        {
          key: 'item_code',
          label: 'Item Code',
          class: 'text-center'
        },
        {
          key: 'item_description',
          class: 'text-center'
        },
        {
          key: 'quantity',
          label: 'Quantity',
          class: 'text-center'
        },
        {
          key: 'name',
          label: 'Vendor Name',
          class: 'text-center'
        },
        {
          key: 'negotiation_seq',
          label: 'Round',
          class: 'text-center'
        },
        {
          key: 'version',
          label: 'Version',
          class: 'text-center'
        },
        {
          key: 'unit_price',
          label: 'Price',
          class: 'text-center'
        },
        {
          key: 'attributes',
          label: 'Attributes',
          class: 'text-center'
        }
      ],
      popoverContent: null,
      rfpExtensionId: null,
      rfpBoqFilesFlag: false,
      showHistory: false,
      historyType: null,
      documentList: [],
      selectedGridData: {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      userId: null,
      roundFieldsComparative: [
        {
          key: 'negotiation_seq',
          label: 'Round',
          class: 'col-fix text-center'
        },
        {
          key: 'start_date',
          label: 'Initiation Date',
          class: 'col-fix text-center'
        },
        {
          key: 'end_date',
          class: 'col-fix text-center'
        },
        {
          key: 'status',
          class: 'col-fix text-center'
        },
        {
          key: 'remarks',
          class: 'col-fix text-center'
        },

        {
          key: 'close_negotiation',
          class: 'col-fix text-center'
        },
        {
          key: 'document',
          class: 'col-fix text-center'
        }
      ],
      showWorkflowHistory: false,
      selectedRfpDetails: {},
      workflowHistoryType: null,
      initiateNegotitionModal: true
    };
  },
  mounted() {
    this.userId = this.$store.state.auth.userId;
    if (this.negotiationAllData && this.negotiationAllData.length > 0) {
      this.getParentToChildData(this.negotiationAllData);
      this.rfpInitiateBidFlag = true;
      if(this.headerAllData && this.headerAllData.approval_status === 'REJECTED' || this.headerAllData.approval_status === 'DRAFT') {
        this.rfpInitiateBidFlag = false;
      }
      this.eventBus.$emit('rfpInitiateBidFlag', this.rfpInitiateBidFlag);
    }
    if (this.rfphdrid) {
      this.rfpHeaderId = this.rfphdrid;
    }
  },
  created() {
    this.eventBus.$on('vendorData', vendorData => {
      this.vendorData = vendorData;
    });
    this.eventBus.$on('rfpExtensionId', rfpExtensionId => {
      this.rfpExtensionId = rfpExtensionId;
      if(this.rfpExtensionId) {
        this.getAllRfpData();
      }
    });
    this.eventBus.$on('roundData', roundData => {
      this.roundData = roundData;
      if (this.roundData.length > 0) {
        this.rfpInitiateBidFlag = true;
        this.eventBus.$emit('rfpInitiateBidFlag', this.rfpInitiateBidFlag);
      }
      this.roundStatus = this.roundData[this.roundData.length - 1].status;
      this.eventBus.$emit('roundStatus', this.roundStatus);
      this.negotiationHdrId = roundData.negotiation_hdr_id;
    });
    this.eventBus.$on('rfpLocationFlag', rfpLocationFlag => {
      this.rfpLocationFlag = rfpLocationFlag;
    });
    this.eventBus.$on('rfpItemFlag', rfpItemFlag => {
      this.rfpItemFlag = rfpItemFlag;
    });
    this.eventBus.$on('rfpBoqFlag', rfpBoqFlag => {
      this.rfpBoqFlag = rfpBoqFlag;
    });
    this.eventBus.$on('rfpClosedFlag', rfpClosedFlag => {
      this.rfpClosedFlag = rfpClosedFlag;
    });
    this.eventBus.$on('itemBasis', rfpBasis => {
      this.itemBasis = rfpBasis;
      if(this.itemBasis === 'boqFiles') {
         this.roundFields = this.roundFieldsComparative;
      }
    });
    this.eventBus.$on('headerId', rfpHeaderId => {
      this.rfpHeaderId = rfpHeaderId;
    });
    this.eventBus.$on('rfpBoqFilesFlag', rfpBoqFilesFlag => {
      this.rfpBoqFilesFlag = rfpBoqFilesFlag;
    });
    this.eventBus.$on('initiateNegotitionModal', initiateNegotitionModal => {
      this.initiateNegotitionModal = initiateNegotitionModal;
      if(!this.initiateNegotitionModal) {
        this.initiateModal(false);
      }
    });
  },
  methods: {
    initiateRound() {
      this.nextRound++;
      this.previousRound++;
      this.currentRound++;
    },
    rowSelected(item) {
      this.negotiationSequence = item.negotiation_seq;
      this.roundProps = {
        negotiation_hdr_id: item.negotiation_hdr_id,
        rfp_header_id: item.rfp_header_id,
        status: item.status
      };
      this.showOpenRoundsModal = true;
    },
    roundsModal(flag) {
      this.showOpenRoundsModal = flag;
    },
    initiateModal(flag) {
      this.showOpenInitiateModal = flag;
    },
    initiateNegotiation() {
      if (this.vendorData.length === 0) {
        alert('Please add vendors first!');
      } else {
        this.showOpenInitiateModal = true;
      }
    },
    closeNegotiation(negotiationheaderid) {
      this.remarks = null;
      this.negotiationHdrId = negotiationheaderid;
      this.showOpenCloseNegotiationModal = true;
    },
    getParentToChildData(data) {
      this.roundData = data;
      for(let i of this.roundData) {
        i._rowVariant = 'info';
      }
      this.roundStatus = this.roundData[this.roundData.length - 1].status;
      this.eventBus.$emit('roundStatus', this.roundStatus);
    },
    closeNegotiationModal(flag, selected) {
      if (selected === 'yes') {
        if (this.remarks) {
          let payload = {
            negotiation_hdr_id: this.negotiationHdrId,
            remarks: this.remarks
          };
          this.loading = true;
          this.$store
            .dispatch('rfp/getStatus', payload)
            .then(response => {
              if (response.status === 200) {
                this.makeToast(response.data.message, 'success');
                this.getAllRfpData();
              }
            })
            .catch(() => {
              this.loading = false;
            });
          this.showOpenCloseNegotiationModal = flag;
          this.autofocus = true;
        } else {
          alert('Please Enter Remarks first');
          this.autofocus = true;
        }
      } else {
        this.showOpenCloseNegotiationModal = flag;
      }
    },
    changeSelectedOption(option) {
      if (option === 'yes') {
        this.roundData[this.roundIndex].status = 'CLOSED';
        this.showOpenCloseNegotiationModal = false;
        this.$emit('getRfpAllData', this.rfpHeaderId);
      } else {
        this.showOpenCloseNegotiationModal = false;
      }
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast',
        autoHideDelay: 2000,
      });
    },
    getAllRfpData() {
      const payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpDataByHeaderId', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data.rfp_negotiation_dtls;
            this.roundData = result;
            this.roundStatus = this.roundData[this.roundData.length - 1].status;
            this.eventBus.$emit('roundStatus', this.roundStatus);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // openGenerateComparativeModal() {
    //   this.showOpenGenerateComparativeModal = true;
    // },
    // generateComparativeModal(flag) {
    //   this.showOpenGenerateComparativeModal = flag;
    // },
    generateNewComparativeModal(flag) {
      this.showOpenNewGenerateComparativeModal = flag;
    },
    openNewGenerativeModal() {
      this.generateComparativeData = [];
      this.showOpenNewGenerateComparativeModal = true;
    },
    getGenerateComparative() {
      this.generateComparativeData = [];
      if (this.itemBasis === 'item') {
        let payload = {
          rfp_header_id: this.rfpHeaderId,
          negotiation_seq: null
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/getRfpNewComparative', payload)
          .then(response => {
            if (response.status === 200) {
              let result = response.data.data;
              this.generateComparativeData = result;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else if (this.itemBasis === 'BOQ') {
        this.generateComparativeFields = [
          {
            key: 'location_name',
            label: 'Location',
            class: 'text-center'
          },
          {
            key: 'boq_line_code',
            label: 'BOQ Line Code',
            class: 'text-center'
          },
          {
            key: 'boq_description',
            label: 'BOQ Description',
            class: 'text-center'
          },
          // {
          //   key: 'boq_long_description',
          //   label: 'BOQ Specification',
          //   class: 'text-center'
          // },
          {
            key: 'boq_quantity',
            label: 'Quantity',
            class: 'text-center'
          },
          {
            key: 'name',
            label: 'Vendor Name',
            class: 'text-center'
          },
          {
            key: 'negotiation_seq',
            label: 'Round',
            class: 'text-center'
          },
          {
            key: 'version',
            label: 'Version',
            class: 'text-center'
          },
          {
            key: 'unit_price',
            label: 'Price',
            class: 'text-center'
          },
          {
            key: 'attributes',
            label: 'Attributes',
            class: 'text-center'
          }
        ];
        let payload = {
          rfp_header_id: this.rfpHeaderId,
          negotiation_seq: null
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/getRfpNewBoqComparative', payload)
          .then(response => {
            if (response.status === 200) {
              let result = response.data.data;
              this.generateComparativeData = result;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: this.$store.state.shared.menuId
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    },
    downloadExcelData() {
      this.makeToast('Excel Sheet downloading!', 'success');
      const ws = XLSX.utils.json_to_sheet(this.generateComparativeData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Comparative');
      XLSX.writeFile(wb, 'comparative.xlsx');
    },
    getOpenKmAuthTokenUploadDocument(negHdrId) {
      this.negotiationHdrId = negHdrId;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideHistory(true, 'invoice', negHdrId);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType, negHdrId) {
      this.showHistory = flag;
      this.historyType = historyType;
      this.getUploadedDocDtl(negHdrId);
    },
    getUploadedDocDtl(negHdrId) {
      const payload = {
        _page: 0,
        _limit: 100,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 91,
        record_id: negHdrId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.documentList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            } else {
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideWorkflowHistory(flag, historyType) {
      // to click initiate approval than visible button
      if (
        (this.selectedRfpDetails.approval_status === 'INPROCESS' ||
          this.selectedRfpDetails.approval_status === 'NOTREQUIRED' ||
          this.selectedRfpDetails.approval_status === 'APPROVED') &&
        historyType === 'uploadDocument'
      ) {
        alert('You Cannot Upload Document in this Stage');
      } else {
        this.showWorkflowHistory = flag;
        this.workflowHistoryType = historyType;
      }
    },
  },
  beforeDestroy() {
    this.eventBus.$off('vendors');
    this.eventBus.$off('rfpInitiateBidFlag');
    this.eventBus.$off('roundStatus');
    this.eventBus.$off('rfpExtensionId');
  }
};
