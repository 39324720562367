import commonHelper from '@/app/utility/common.helper.utility';
import awardDetails from "./awardDetails";
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';

export default {
  name: 'awardSummary',
  props: ['rfpHeaderId', 'rankedVendors', 'headerAllData'],
  components: {
    awardDetails,
    UploadDocument,
    HistoryRecord,
    ApprovalHistory,
    sendForApproval
  },
  data() {
    return {
      loading: false,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      showBtn: false,
      awardData: [],
      awardFields: [
        {
          key: 'select',
          variant: 'info',
          stickyColumn: true
        },
        {
          key: 'rfp_award_vendor_email',
          label: 'Vendor Email'
        },
        {
          key: 'rfp_award_vendor_name',
          label: 'Vendor Name'
        },
        {
          key: 'vendor_code',
          class: 'col-fix-small text-left'
        },
        {
          key: 'rank',
          label: 'Level',
          class: 'col-fix-small text-left'
        },
        // {
        //   key: 'details',
        //   class: 'col-fix-small text-left'
        // },
      ],
      rfpAwardVendorId: null,
      selectedIndex: null,
      awardDetailsModal: false,
      remarksModal: false,
      remarks: null,
      rowData: null,
      showWorkflowHistory: false,
      selectedRfpDetails: {},
      workflowHistoryType: null,
      menuIdForRfpAward: null,
      editMode: true
    };
  },
  mounted() {
    this.getAwardSummaryData();
    this.getAwardSummaryDtlById();
  },
  watch: {
    currentPage: function() {
      this.getAwardSummaryData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAwardSummaryData();
    }
  },
  methods: {
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast',
        autoHideDelay: 2000
      });
    },
    getAwardSummaryData() {
      let payload = {
        rfp_header_id: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getAwardSummaryData', payload)
        .then(response => {
          if (response.status === 200) {
            this.loading = false;
            this.awardData = response.data.data;
            let mergedData = this.awardData.map(awardVendor => {
              let rankInfo = this.rankedVendors.find(
                rank => rank.vendor === awardVendor.rfp_award_vendor_name
              );
              return {
                ...awardVendor,
                rank: rankInfo ? rankInfo.rank : 'N/A',
                total: rankInfo ? rankInfo.total : 'N/A',
                _rowVariant: rankInfo && rankInfo.rank === "L1" ? "success" : null
              };
            });
            this.awardData = mergedData;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectedAwardDetail(item, index) {
      this.selectedIndex = index;
      this.selectedDetails = Object.assign({}, item);
      this.showBtn = true;
    },
    alotAwardDetail() {
      if(this.remarks === null || this.remarks === "") {
        alert('Remarks Mandatory')
      } else {
        const poTypeDetails = [
          {
            rfp_award_vendor_id: this.rfpAwardVendorId
              ? this.rfpAwardVendorId
              : 0,
            rfp_vendor_id: this.selectedDetails.rfp_vendor_id,
            rfp_award_vendor_name: this.selectedDetails.rfp_award_vendor_name,
            rfp_award_vendor_email: this.selectedDetails.rfp_award_vendor_email,
            vendor_code: this.selectedDetails.vendor_code,
            is_delete: 0,
            rfp_award_vendor_remarks: this.remarks
          }
        ];
        const payload = {
          rfp_header_id: this.rfpHeaderId,
          rfp_vendor_award_dtls: poTypeDetails ? poTypeDetails : null
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/alotAwardDetail', payload)
          .then(response => {
            this.loading = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.makeToast('Request Completed Successfully', 'success');
              this.getAwardSummaryData();
              this.getAwardSummaryDtlById();
              this.$emit('openAwardSummaryModal', false);
              this.remarksModal = false;
            }
          })
          .catch(() => {
            this.loading = false;
            this.makeToast('Something went wrong', 'danger');
          });
      }   
    },
    getAwardSummaryDtlById() {
      const payload = {
        rfp_header_id: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getAwardSummaryDtlById', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            this.menuIdForRfpAward = resp.data.data[0].menu_id;
            this.selectedAwardData = resp.data.data;
            if(this.selectedAwardData[0].approval_status === 'INPROCESS' || this.selectedAwardData[0].approval_status === 'APPROVED') {
              this.editMode = false;
            } else {
              this.editMode = true;
            }
            this.rfpAwardVendorId = resp.data.data[0].rfp_award_vendor_id;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openCloseAwardDetailsModal(flag, item) {
      this.rowData = item;
      this.awardDetailsModal = flag;
    },
    openCloseRemarksModal(flag) {
      this.remarksModal = flag;
    },
    showHideWorkflowHistory(flag, historyType) {
      if (
        (this.selectedRfpDetails.approval_status === 'INPROCESS' ||
          this.selectedRfpDetails.approval_status === 'NOTREQUIRED' ||
          this.selectedRfpDetails.approval_status === 'APPROVED') &&
        historyType === 'uploadDocument'
      ) {
        alert('You Cannot Upload Document in this Stage');
      } else {
        this.showWorkflowHistory = flag;
        this.workflowHistoryType = historyType;
      }
    },
  },
  beforeDestroy() {}
};
