import generateComparative from "./generateComparative";
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';



export default {
  name: 'round',
  props: ['roundProps', 'itemBasis', 'rfpHeaderId', 'negotiationSequence', 'headerAllData', 'menuFromDashboard'],
  components: {
    generateComparative,
    UploadDocument,
  },
  data() {
    return {
      roundData: [],
      roundFields: [
        {
          key: 'name',
          class: 'text-center'
        },
        {
          key: 'rfp_number',
          label: 'RFP Number',
          class: 'text-center'
        },
        {
          key: 'pan_no',
          label: 'PAN No',
          class: 'text-center'
        },
        {
          key: 'email',
          class: 'text-center'
        },
        {
          key: 'payment_terms',
          class: 'text-center'
        },   
        {
          key: 'payment_terms_remarks',
          class: 'text-center'
        },
        {
          key: 'bid_submit_status',
          label: 'Bid Status',
          class: 'text-center'
        },
        {
          key: 'view_document',
          class: 'text-center'
        }
      ],
      currentPage: 1,
      perPage: 50,
      showOpenGenerateComparativeModal: false,
      loading: false,
      rfpBasis: null,
      popoverContent: null,
      formType: null,
      openKmAuth: null,
      showHistory: false,
      historyType: null,
      editMode: true,
      isDisabled: false,
      documentList: [],
      selectedGridData: {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      bidHeaderId: null,
      userid: null,
      rfpType: {
        value: null,
        text: null
      },
      bidPendingFlag: false,
      paymentTerms: null,
      
      roundNumber: null
    };
  },
  created() {
    this.eventBus.$on('userId', userId => {
      this.userid = userId;
    });
  },
  mounted() {
    this.roundNumber = this.negotiationSequence-1;
    if(this.rfpHeaderId) {
      this.getHeaderData(this.rfpHeaderId);
    }
    if (this.roundProps) {
      setTimeout(() => {
        this.getRoundData(this.roundProps);
      }, 2000)
      
    }
    this.rfpBasis = this.itemBasis;
  },
  methods: {
    getRoundData(props) {
      let payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        negotiation_hdr_id: props.negotiation_hdr_id,
        rfp_header_id: props.rfp_header_id
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getNegotiationRoundData', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            let result = response.data.data.page;
            this.roundData = result;
            for(let i of this.roundData) {
              i.payment_terms = this.paymentTerms;
            }
            this.checkBidPending();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openGenerateComparativeModal() {
      this.showOpenGenerateComparativeModal = true;
    },
    generateComparativeModal(flag) {
      this.showOpenGenerateComparativeModal = flag;
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: this.$store.state.shared.menuId
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    },
    viewUploadedDocuments(item) {
      this.bidHeaderId = item.bid_hdr_id;
      this.formType = 'rfpBidDocument';
      this.getOpenKmAuthTokenUploadDocument();
    },
    getOpenKmAuthTokenUploadDocument() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideHistory(true, 'invoice');
              this.editMode = false;
              this.isDisabled = true;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
      // this.getUploadedDocDtl();
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: 100,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 89,
        record_id: this.bidHeaderId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.documentList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
              this.totalRows = response.data.data.total_elements;
            }
             else {
              // alert('Document not available for this record');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getHeaderData(rfpHdrId) {
      let payload = {
        headerId: rfpHdrId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpHeaderData', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.paymentTerms = result.payment_terms;
            this.rfpType = {
              value: result.rfp_type,
              text: result.rfp_type_meaning
            };
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    checkBidPending() {
      let count=0;
      for(let i of this.roundData) {
         if(i.bid_submit_status === 'Bid Submitted') {
          count=count+1;
         }
      }
      if(count===this.roundData.length) {
        this.bidPendingFlag = true;
      }
    },
    
    postNegotiationDetailsData() {
      let vendor = [];
      for (let i of this.selectedRow) {
        vendor.push({
          negotiation_vendor_id: 0,
          rfp_vendor_id: i.rfp_vendor_id
        });
      }
      let payload = {
        negotiation_hdr_id: 0,
        rfp_header_id: this.rfpheaderid,
        negotiation_seq: 1,
        status: this.status,
        negotiation_vendor_dtls: vendor
      };
      this.$store
        .dispatch('rfp/postNegotiationDetails', payload)
        .then(response => {
          if (response.status === 200) {
            this.responseMsg = response.data.message;
            this.makeToast(this.responseMsg, 'success');
            this.negotiationHeaderId = response.data.data.negotiation_hdr_id;
            // this.getNegotiationDetailsData();
            this.getAllRfpData();
          } else {
            this.loading = false;
            this.responseMsg = response.response.data.message;
            this.makeToast(this.responseMsg, 'warning');
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
    },
    getRfpData() {
      const payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpDataByHeaderId', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            if(result.approval_status === "APPROVED" && result.rfp_negotiation_dtls[this.roundNumber].rfp_initiation_round_wf_status === 0) {
              this.sendBid();
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    sendBid() {
      let vendor = [];
      for (let i of this.roundData) {
        vendor.push({
          negotiation_vendor_id: i.negotiation_vendor_id,
          rfp_vendor_id: i.rfp_vendor_id
        });
      }
      let payload = {
        negotiation_hdr_id: this.roundData[0].negotiation_hdr_id,
        rfp_header_id: this.roundData[0].rfp_header_id,
        negotiation_seq: this.negotiationSequence,
        status: 'INITIATED',
        negotiation_vendor_dtls: vendor
      };
      this.$store
        .dispatch('rfp/postNegotiationDetails', payload)
        .then(response => {
          if (response.status === 200) {
            this.responseMsg = response.data.message;
            this.makeToast(this.responseMsg, 'success');
          } else {
            this.loading = false;
            this.responseMsg = response.response.data.message;
            this.makeToast(this.responseMsg, 'warning');
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast',
        autoHideDelay: 2000,
      });
    },
    getDataVariable() {
      
    }
  }
};
